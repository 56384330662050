import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController, Events, LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
var RateProductPage = /** @class */ (function () {
    function RateProductPage(modalController, events, labelService, loadingController, alertController) {
        this.modalController = modalController;
        this.events = events;
        this.labelService = labelService;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.reviewPh = '';
        this.ratingData = {
            status: 'pending',
            rating: 5,
            review: '',
            photos: [],
            userName: ''
        };
        this.rating = 5;
        this.productIndex = -1;
        this.orderId = '';
    }
    RateProductPage.prototype.ngOnInit = function () {
        // console.log(this.productId);
        // console.log(this.product);
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.RATE_PRODUCT_LABELS = this.labelService.labels['RATE_PRODUCT'];
        this.reviewPh = this.RATE_PRODUCT_LABELS['review_placeholder'];
    };
    RateProductPage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.initializeSubscriptions();
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        this.events.publish('rating:checkProductBought', this.productId, this.orderId ? 'order-product' : '');
                        return [2 /*return*/];
                }
            });
        });
    };
    RateProductPage.prototype.ionViewWillLeave = function () {
        this.removeSubscriptions();
    };
    RateProductPage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('rating:submitRatingSuccess', function () {
            if (_this.loading) {
                _this.loading.dismiss();
            }
            ;
            _this.presentAlert(_this.RATE_PRODUCT_LABELS['thanks_for_rating'], true);
        });
        this.events.subscribe('rating:productChecked', function (isProductBought) {
            if (_this.loading) {
                _this.loading.dismiss();
            }
            ;
            if (!isProductBought) {
                _this.presentAlert(_this.RATE_PRODUCT_LABELS['product_cant_review'], true);
            }
        });
        this.events.subscribe('rating:productReviewed', function () {
            if (_this.loading) {
                _this.loading.dismiss();
            }
            ;
            _this.presentAlert(_this.RATE_PRODUCT_LABELS['product_already_reviewed'], true);
        });
    };
    RateProductPage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('rating:submitRatingSuccess');
        this.events.unsubscribe('rating:productChecked');
        this.events.unsubscribe('rating:productReviewed');
    };
    RateProductPage.prototype.closeModal = function (action) {
        this.modalController.dismiss(action);
    };
    RateProductPage.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.ratingData.userName) return [3 /*break*/, 1];
                        this.presentAlert(this.RATE_PRODUCT_LABELS['enter_public_name'], false);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.presentLoading()];
                    case 2:
                        _a.sent();
                        if (this.parentProductId && this.orderId) {
                            this.events.publish('rating:submitRating', this.ratingData, this.parentProductId, this.productIndex, this.orderId);
                        }
                        this.events.publish('rating:submitRating', this.ratingData, this.productId);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RateProductPage.prototype.rate = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ratingData.rating = data.rating;
                        this.ratingData.review = data.review;
                        this.ratingData.photos = data.photos;
                        this.ratingData.userName = data.userName;
                        return [4 /*yield*/, this.submit()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RateProductPage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                                duration: 10000,
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RateProductPage.prototype.presentAlert = function (msg, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            backdropDismiss: false,
                            buttons: [{
                                    text: this.SHARED_LABELS['ok'],
                                    handler: function () {
                                        if (action) {
                                            _this.closeModal(_this.orderId ? 'order-product' : '');
                                        }
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return RateProductPage;
}());
export { RateProductPage };
