import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Events } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { SharedService } from '../shared/shared.service';
import { UtilsService } from '../utils/utils.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
import * as i4 from "../shared/shared.service";
import * as i5 from "../utils/utils.service";
import * as i6 from "@angular/fire/storage";
import * as i7 from "../config/config.service";
var RatingService = /** @class */ (function () {
    function RatingService(events, afs, logglyService, sharedService, utilsService, fbStorage, configService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.sharedService = sharedService;
        this.utilsService = utilsService;
        this.fbStorage = fbStorage;
        this.configService = configService;
        this.allRatings = [];
    }
    RatingService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('rating:submitRating', function (data, productId, productIndex, orderId) {
            _this.submitRating(data, productId, productIndex, orderId);
        });
        this.events.subscribe('rating:submitOrderRating', function (data, orderId) {
            _this.submitOrderRating(data, orderId);
        });
        this.events.subscribe('rating:checkProductBought', function (productId, action) {
            _this.checkProductBought(productId, action);
        });
        this.events.subscribe('rating:getLimitedProductRatings', function (productId) {
            _this.getLimitedProductRatings(productId);
        });
        this.events.subscribe('rating:getRatings', function (productId) {
            _this.getRatings(productId);
        });
        this.events.subscribe('rating:getMoreRatings', function (productId) {
            _this.getMoreRatings(productId);
        });
    };
    RatingService.prototype.submitRating = function (ratingData, productId, productIndex, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, ratingRef, data, _i, _a, img, imgRef, order, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 10, , 11]);
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        uid = _b.sent();
                        ratingRef = this.afs.collection('products').doc(productId).collection('ratings').doc(uid);
                        data = {};
                        if (ratingData.photos.length > 0) {
                            data = JSON.parse(JSON.stringify(ratingData));
                            delete ratingData.photos;
                        }
                        ratingData['createdAt'] = new Date();
                        return [4 /*yield*/, ratingRef.set(ratingData)];
                    case 2:
                        _b.sent();
                        if (!(data && data.photos && data.photos.length)) return [3 /*break*/, 6];
                        _i = 0, _a = data.photos;
                        _b.label = 3;
                    case 3:
                        if (!(_i < _a.length)) return [3 /*break*/, 6];
                        img = _a[_i];
                        imgRef = this.fbStorage.ref("productRatings/" + productId + "/" + uid + "/images/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(img.url, 'data_url')];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6:
                        if (!(productIndex && orderId && productIndex !== -1)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).valueChanges().pipe(first()).toPromise()];
                    case 7:
                        order = _b.sent();
                        order.products[productIndex]['rating'] = ratingData.rating;
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                                products: order.products
                            })];
                    case 8:
                        _b.sent();
                        _b.label = 9;
                    case 9:
                        this.events.publish('rating:submitRatingSuccess');
                        return [3 /*break*/, 11];
                    case 10:
                        error_1 = _b.sent();
                        console.dir(error_1);
                        error_1['location'] = 'rating-service:submitRating';
                        this.logglyService.log(error_1);
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    RatingService.prototype.submitOrderRating = function (ratingData, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, data, _i, _a, img, imgRef, error_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.utilsService.consoleLog('order rating service order id', orderId);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 7, , 8]);
                        orderRef = this.afs.collection('orders').doc(orderId);
                        data = {};
                        data = JSON.parse(JSON.stringify(ratingData));
                        if (ratingData.photos.length > 0) {
                            delete data.photos;
                        }
                        data['createdAt'] = new Date();
                        return [4 /*yield*/, orderRef.update({ rating: data })];
                    case 2:
                        _b.sent();
                        if (!(ratingData.photos.length > 0)) return [3 /*break*/, 6];
                        _i = 0, _a = ratingData.photos;
                        _b.label = 3;
                    case 3:
                        if (!(_i < _a.length)) return [3 /*break*/, 6];
                        img = _a[_i];
                        imgRef = this.fbStorage.ref("orders/" + orderId + "/rating/images/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(img.url, 'data_url')];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6:
                        this.events.publish('rating:submitOrderRatingSuccess');
                        return [3 /*break*/, 8];
                    case 7:
                        error_2 = _b.sent();
                        console.dir(error_2);
                        error_2['location'] = 'rating-service:submitRating';
                        this.logglyService.log(error_2);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    RatingService.prototype.checkProductBought = function (pid, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isProductBought, uid_1, ordersRef, orders, _i, orders_1, order, _a, _b, pdt, userRating, error_3;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 8, , 9]);
                        isProductBought = false;
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        uid_1 = _c.sent();
                        if (!(action === 'order-product')) return [3 /*break*/, 2];
                        isProductBought = true;
                        return [3 /*break*/, 4];
                    case 2:
                        ordersRef = this.afs.collection('orders', function (ref) { return ref
                            .where('userId', '==', uid_1)
                            .where('status', '==', 'Delivered'); });
                        return [4 /*yield*/, ordersRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 3:
                        orders = _c.sent();
                        isProductBought = false;
                        for (_i = 0, orders_1 = orders; _i < orders_1.length; _i++) {
                            order = orders_1[_i];
                            if (order && order.products) {
                                for (_a = 0, _b = order.products; _a < _b.length; _a++) {
                                    pdt = _b[_a];
                                    if (pdt.productId === pid) {
                                        isProductBought = true;
                                        break;
                                    }
                                }
                                if (isProductBought) {
                                    break;
                                }
                            }
                        }
                        _c.label = 4;
                    case 4:
                        if (!isProductBought) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('ratings').doc(uid_1).valueChanges().pipe(first()).toPromise()];
                    case 5:
                        userRating = _c.sent();
                        if (!userRating) {
                            this.events.publish('rating:productChecked', true);
                        }
                        else {
                            this.events.publish('rating:productReviewed');
                        }
                        return [3 /*break*/, 7];
                    case 6:
                        this.events.publish('rating:productChecked', false);
                        _c.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        error_3 = _c.sent();
                        console.dir(error_3);
                        error_3['location'] = 'rating-service:checkProductBought';
                        this.logglyService.log(error_3);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    RatingService.prototype.getLimitedProductRatings = function (pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ratingsDocData, ratings, ratingsDoc, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        ratingsDocData = null;
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('ratings', function (ref) { return ref
                                .where('status', '==', 'approved')
                                .orderBy('createdAt', 'desc')
                                .limit(3); })
                                .valueChanges().pipe(first()).toPromise()];
                    case 1:
                        ratings = _a.sent();
                        if (!ratings.length) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).valueChanges().pipe(first()).toPromise()];
                    case 2:
                        ratingsDoc = _a.sent();
                        ratingsDocData = ratingsDoc.rating;
                        _a.label = 3;
                    case 3:
                        this.events.publish('rating:limitedProductRatings', ratings, ratingsDocData);
                        return [3 /*break*/, 5];
                    case 4:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        error_4['location'] = 'rating-service:getLimitedProductRatings';
                        this.logglyService.log(error_4);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    RatingService.prototype.getRatings = function (pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ratings, _i, ratings_1, rat, error_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.allRatings = [];
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('ratings', function (ref) { return ref
                                .where('status', '==', 'approved')
                                .orderBy('createdAt', 'desc')
                                .limit(_this.configService.environment.scrollLimit); })
                                .snapshotChanges().pipe(first()).toPromise()];
                    case 1:
                        ratings = _a.sent();
                        if (ratings.length) {
                            this.lastRating = ratings[ratings.length - 1].payload.doc;
                            for (_i = 0, ratings_1 = ratings; _i < ratings_1.length; _i++) {
                                rat = ratings_1[_i];
                                this.allRatings.push(tslib_1.__assign({ id: rat.payload.doc.id }, rat.payload.doc.data()));
                            }
                        }
                        this.events.publish('rating:productRatings', this.allRatings);
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        error_5['location'] = 'rating-service:getRatings';
                        this.logglyService.log(error_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RatingService.prototype.getMoreRatings = function (pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ratings, _i, ratings_2, rat, error_6;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('ratings', function (ref) { return ref
                                .where('status', '==', 'approved')
                                .orderBy('createdAt', 'desc')
                                .limit(_this.configService.environment.scrollLimit)
                                .startAfter(_this.lastRating); }).snapshotChanges().pipe(first()).toPromise()];
                    case 1:
                        ratings = _a.sent();
                        if (ratings.length > 0) {
                            this.lastRating = ratings[ratings.length - 1].payload.doc;
                            for (_i = 0, ratings_2 = ratings; _i < ratings_2.length; _i++) {
                                rat = ratings_2[_i];
                                this.allRatings.push(tslib_1.__assign({ id: rat.payload.doc.id }, rat.payload.doc.data()));
                            }
                        }
                        else {
                            this.events.publish('rating:noMoreRatings');
                        }
                        this.events.publish('rating:productRatings', this.allRatings);
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        error_6['location'] = 'rating-service:getMoreRatings';
                        this.logglyService.log(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RatingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RatingService_Factory() { return new RatingService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService), i0.ɵɵinject(i4.SharedService), i0.ɵɵinject(i5.UtilsService), i0.ɵɵinject(i6.AngularFireStorage), i0.ɵɵinject(i7.ConfigService)); }, token: RatingService, providedIn: "root" });
    return RatingService;
}());
export { RatingService };
